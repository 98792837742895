import React from 'react';
import './styles.css';

export default function Lanzamientos() {
    return(
    <div className='Lanzamientos_Container'>
        <div className='Lanzamientos_Nuevo'> 
            <div className='Lanzamientos_ImagenReciente'> <img className='Lanzamientos_LogoReciente' src={require('./media/CorazonIdiota.png')}></img></div>
            <div className='Lanzamientos_VideoReciente'>
                <iframe className='Lanzamientos_VideoPlayer' width="currentWidth" height="currentHeight" src="https://www.youtube-nocookie.com/embed/UeYGxyU2DV0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>

            <div className='Lanzamientos_SpotifyReciente'>
                <iframe className='Lanzamientos_SpotifyPlayer' src="https://open.spotify.com/embed/track/2VCJteflOh44PDvO9Hw89Z?utm_source=generator" width="300" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>
        </div>
        <div className='Lanzamientos_Recientes'>
        <div className='Lanzamientos_Recientes_Descripcion'>
                Escucha nuestros lanzamientos más recientes:
        </div>
        <div className='Lanzamientos_ListaRecientes'>
            <div className='Lanzamientos_SpotifyReciente'>
            <iframe className='Lanzamientos_SpotifyPlayer' src="https://open.spotify.com/embed/track/31DtW1JEHLlnjhYROwGvNu?utm_source=generator" width="300" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>
            <div className='Lanzamientos_SpotifyReciente'>
            <iframe className='Lanzamientos_SpotifyPlayer' src="https://open.spotify.com/embed/track/2bT1WL7MGRmERo42MiOLf9?utm_source=generator" width="300" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>
            <div className='Lanzamientos_SpotifyReciente'>
            <iframe className='Lanzamientos_SpotifyPlayer' src="https://open.spotify.com/embed/track/0LX4Q41Y4AmRVeXjgVbWya?utm_source=generator" width="300" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>
        </div>
        </div>
    </div>
    )
}

/*

<div className='Lanzamientos_StreamingLinkList'>
            <h1 className='Lanzamientos_StreamingLinkListTitle'>Escuchalo en:</h1>
            <div className='Lanzamientos_StreamingLink'><div className='Lanzamientos_StreamingLinkImage'></div> <div className='Lanzamientos_StreamingLinkButton'></div></div>
        </div>

*/