import {React, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, onSnapshot } from "firebase/firestore";
import {db} from './firebase-config';

function Eventos() {

    const [eventos, setEventos] = useState([]);
    const collectionRef = collection(db, ("Eventos"));
    const q = query(collectionRef);

    useEffect(() => {
        const unsub = onSnapshot(q, (snapshot) =>
            setEventos(snapshot.docs.map((doc) => (
                {...doc.data(), id: doc.id }
                )))
        );
    return unsub;
    }, []);

    return(
      <div className='Eventos_ListaEventos'>
        { eventos.map((evento) => { 
            if( true ) {      
                return( 
                    <div className={'Eventos_CartaEvento'} key={evento.id}>
                        <h1> {evento.Nombre} </h1>
                        <h3> {evento.Fecha} </h3>
                        <h4> {evento.Lugar} </h4>
                        <p> {evento.Detalles} </p>
                        <a className='Eventos_SeccionBoletos' href={evento.URL_Boletos}><button> ¡Compra tus boletos! </button></a>
                    </div> 
                );
            } })}
      </div>
      
    )
  }
  
  export default Eventos