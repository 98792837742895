import React from 'react';
import './styles.css';
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Literata"></link>
export default function TopMenu(){

    return (
        <div className='TopMenu_TopMenuC'>

<div className='SocialMediaFlexContainer'>
    <div className='TopMenu_SocialMediaLink'> <a href="https://www.facebook.com/LaCaseteraMX">
        <svg className='TopMenu_SocialMediaLinkIcon_Logo' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="40px" height="40px"> <path fill='currentColor' d="M12,2C6.477,2,2,6.477,2,12c0,5.013,3.693,9.153,8.505,9.876V14.65H8.031v-2.629h2.474v-1.749 c0-2.896,1.411-4.167,3.818-4.167c1.153,0,1.762,0.085,2.051,0.124v2.294h-1.642c-1.022,0-1.379,0.969-1.379,2.061v1.437h2.995 l-0.406,2.629h-2.588v7.247C18.235,21.236,22,17.062,22,12C22,6.477,17.523,2,12,2z"/></svg>
    </a>
    </div>  
    <div className='TopMenu_SocialMediaLink'> <a href="https://www.instagram.com/lacaseteramx/"> 
        <svg className='TopMenu_SocialMediaLinkIcon_Logo' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="40px" height="40px"> <path fill='currentColor' d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z"/></svg>
        </a>
    </div> 
    <div className='TopMenu_SocialMediaLink'> <a href="https://twitter.com/LaCaseteraMX">    
        <svg className='TopMenu_SocialMediaLinkIcon_Logo' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="40px" height="40px"> <path fill='currentColor' d="M22,3.999c-0.78,0.463-2.345,1.094-3.265,1.276c-0.027,0.007-0.049,0.016-0.075,0.023c-0.813-0.802-1.927-1.299-3.16-1.299 c-2.485,0-4.5,2.015-4.5,4.5c0,0.131-0.011,0.372,0,0.5c-3.353,0-5.905-1.756-7.735-4c-0.199,0.5-0.286,1.29-0.286,2.032 c0,1.401,1.095,2.777,2.8,3.63c-0.314,0.081-0.66,0.139-1.02,0.139c-0.581,0-1.196-0.153-1.759-0.617c0,0.017,0,0.033,0,0.051 c0,1.958,2.078,3.291,3.926,3.662c-0.375,0.221-1.131,0.243-1.5,0.243c-0.26,0-1.18-0.119-1.426-0.165 c0.514,1.605,2.368,2.507,4.135,2.539c-1.382,1.084-2.341,1.486-5.171,1.486H2C3.788,19.145,6.065,20,8.347,20 C15.777,20,20,14.337,20,8.999c0-0.086-0.002-0.266-0.005-0.447C19.995,8.534,20,8.517,20,8.499c0-0.027-0.008-0.053-0.008-0.08 c-0.003-0.136-0.006-0.263-0.009-0.329c0.79-0.57,1.475-1.281,2.017-2.091c-0.725,0.322-1.503,0.538-2.32,0.636 C20.514,6.135,21.699,4.943,22,3.999z"/></svg>
        </a>
    </div> 
    <div className='TopMenu_SocialMediaLink'> <a href="https://open.spotify.com/artist/41pw8Rr71ZIY5mpOXzviAM?si=HPV39e3PRXi-mq2pNyhCEg">
        <svg className='TopMenu_SocialMediaLinkIcon_Logo' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 14 14" width="40px" height="40px"> <path fill='currentColor' d="M 7,1 C 3.7,1 1,3.7 1,7 c 0,3.3 2.7,6 6,6 3.3,0 6,-2.7 6,-6 C 13,3.7 10.33,1 7,1 Z M 9.7605,9.67 C 9.6405,9.8495 9.4305,9.91 9.25,9.79 7.84,8.92 6.07,8.7395 3.9695,9.2195 3.7605,9.2805 3.58,9.13 3.52,8.95 3.46,8.7395 3.61,8.56 3.79,8.5 6.07,7.9895 8.05,8.2 9.61,9.16 9.82,9.25 9.8495,9.4895 9.7605,9.67 Z m 0.72,-1.65 C 10.33,8.23 10.06,8.32 9.8495,8.17 8.23,7.18 5.77,6.88 3.88,7.48 3.6405,7.54 3.37,7.42 3.31,7.18 3.25,6.94 3.37,6.6695 3.61,6.6095 5.8,5.95 8.5,6.2805 10.36,7.42 c 0.1805,0.0905 0.27,0.39 0.1205,0.6 z m 0.06,-1.68 C 8.62,5.2 5.41,5.08 3.58,5.6505 3.28,5.74 2.98,5.56 2.89,5.29 2.8,4.9895 2.98,4.69 3.25,4.5995 5.38,3.9695 8.89,4.0895 11.1105,5.41 11.38,5.56 11.47,5.92 11.32,6.19 11.1705,6.4005 10.81,6.4895 10.5405,6.34 Z"/></svg>
        </a>
    </div> 
    <div className='TopMenu_SocialMediaLink'> <a href="https://www.youtube.com/@LaCasetera">        
        <svg className='TopMenu_SocialMediaLinkIcon_Logo' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512" width="40px" height="40px"><path fill='currentColor' d="M260.4,449c-57.1-1.8-111.4-3.2-165.7-5.3c-11.7-0.5-23.6-2.3-35-5c-21.4-5-36.2-17.9-43.8-39c-6.1-17-8.3-34.5-9.9-52.3   C2.5,305.6,2.5,263.8,4.2,222c1-23.6,1.6-47.4,7.9-70.3c3.8-13.7,8.4-27.1,19.5-37c11.7-10.5,25.4-16.8,41-17.5   c42.8-2.1,85.5-4.7,128.3-5.1c57.6-0.6,115.3,0.2,172.9,1.3c24.9,0.5,50,1.8,74.7,5c22.6,3,39.5,15.6,48.5,37.6   c6.9,16.9,9.5,34.6,11,52.6c3.9,45.1,4,90.2,1.8,135.3c-1.1,22.9-2.2,45.9-8.7,68.2c-7.4,25.6-23.1,42.5-49.3,48.3   c-10.2,2.2-20.8,3-31.2,3.4C366.2,445.7,311.9,447.4,260.4,449z M205.1,335.3c45.6-23.6,90.7-47,136.7-70.9   c-45.9-24-91-47.5-136.7-71.4C205.1,240.7,205.1,287.6,205.1,335.3z"/></svg>    
        </a>
    </div> 
    </div>

        <div className='TopMenu_TopMenu'>
            <a className='TopMenu_Link' href='/'>INICIO</a>
            <a className='TopMenu_Link' href='/fotos'>FOTOS</a>
            <a className='TopMenu_Link' href='/eventos'>EVENTOS</a>
            <a className='TopMenu_Link' href='https://negropasion.com/collections/la-casetera'>MERCH</a>
        </div>    
        </div>
        
    )
}