import React from 'react';
import './styles.css';
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Literata"></link>
export default function MainpageLogo(){

    return (
        <div className='mainPageLogoContainer'>
            <div className='mainPageLogoImage'>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1411.000000 255.000000">
                <g transform="translate(0.000000,255.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
                    <path d="M1072 2495 c-249 -45 -472 -165 -647 -346 -235 -244 -345 -528 -345 -894 0 -219 38 -402 119 -571 63 -132 116 -209 215 -313 180 -188 389 -303 639 -352 96 -19 228 -19 6021 -19 5284 0 5933 2 6012 15 490 86 882 482 969 979 20 119 20 403 0 522 -87 499 -473 886 -974 979 -74 13 -705 15 -6010 14 -4855 -1 -5939 -3 -5999 -14z m11974 -120 c472 -76 836 -458 895 -938 18 -143 6 -380 -25 -499 -63 -246 -203 -456 -405 -609 -92 -69 -248 -144 -366 -177 l-100 -27 -5910 -3 c-4457 -2 -5932 0 -6000 9 -176 22 -354 97 -503 209 -189 142 -323 342 -390 580 -26 91 -27 105 -27 335 0 217 2 248 23 322 114 419 441 722 859 797 121 22 11814 23 11949 1z"/>
                    <path d="M4190 2038 c-418 -59 -725 -459 -671 -874 49 -374 324 -648 696 -696 242 -31 518 71 675 249 22 25 43 43 46 40 4 -3 -10 -47 -30 -97 -20 -49 -36 -92 -36 -95 0 -3 74 -5 164 -5 l164 0 41 108 c23 59 52 133 64 165 l22 57 235 0 235 0 61 -163 61 -162 168 -3 c182 -3 173 -6 150 50 -5 14 6 12 67 -12 105 -41 224 -61 364 -61 204 0 325 40 430 145 93 93 131 221 104 352 -35 170 -142 253 -467 360 -190 63 -198 67 -235 102 -22 20 -28 35 -28 69 0 91 64 129 215 127 105 -1 192 -21 291 -68 l71 -33 62 124 61 124 -84 40 c-128 59 -219 80 -377 86 -107 4 -154 2 -212 -11 -149 -33 -259 -113 -315 -226 -24 -50 -27 -66 -27 -165 0 -100 2 -115 28 -167 58 -118 165 -184 440 -273 95 -30 187 -64 205 -75 72 -44 80 -133 17 -188 -104 -92 -380 -55 -581 78 l-32 21 -34 -63 c-19 -35 -39 -62 -43 -61 -5 1 -69 154 -142 340 -74 186 -173 436 -220 556 l-86 217 -147 0 -148 0 -27 -67 c-28 -72 -356 -909 -374 -955 l-10 -27 -200 115 c-110 64 -205 113 -211 109 -6 -3 -20 -25 -31 -47 -24 -46 -29 -38 68 -93 34 -20 65 -39 68 -44 9 -16 -99 -103 -170 -137 -38 -18 -94 -37 -122 -42 l-53 -9 0 98 0 99 -55 0 -55 0 0 -95 c0 -110 2 -108 -95 -75 -78 27 -142 69 -205 136 -60 63 -64 51 48 118 l62 38 -25 41 c-13 23 -29 43 -35 45 -6 2 -42 -15 -81 -38 -39 -23 -72 -40 -73 -38 -11 13 -27 82 -32 137 -6 67 16 205 35 224 6 6 36 -7 78 -32 l68 -41 17 23 c9 12 23 33 30 46 15 27 12 29 -81 86 l-53 32 38 43 c22 24 66 61 98 82 60 38 180 83 197 72 5 -3 9 -39 9 -80 l0 -74 55 0 55 0 0 81 0 81 48 -6 c62 -9 174 -63 240 -115 28 -23 52 -45 52 -49 0 -4 -32 -25 -70 -47 -38 -22 -70 -43 -70 -46 0 -4 12 -27 26 -53 l26 -46 31 19 c18 11 106 63 197 116 91 53 167 98 169 101 9 9 -77 124 -140 186 -115 114 -279 194 -444 218 -82 11 -136 11 -225 -2z m1446 -693 l72 -185 -149 0 -149 0 24 63 c85 223 119 307 125 307 3 0 38 -83 77 -185z"/>
                    <path d="M1010 1255 l0 -695 578 0 577 0 62 162 61 163 237 0 237 0 62 -160 61 -160 169 -3 c102 -1 166 1 164 7 -1 5 -124 318 -273 695 l-270 686 -150 -2 -149 -3 -241 -615 -240 -614 -3 62 -3 62 -279 0 -280 0 0 555 0 555 -160 0 -160 0 0 -695z m1595 86 c36 -94 65 -173 65 -176 0 -3 -65 -5 -145 -5 -80 0 -145 3 -145 6 0 3 32 89 72 190 50 130 74 181 80 171 4 -9 37 -92 73 -186z"/>
                    <path d="M7300 1255 l0 -695 495 0 495 0 0 140 0 140 -340 0 -340 0 0 135 0 135 315 0 315 0 0 135 0 135 -315 0 -315 0 0 145 0 145 340 0 340 0 0 140 0 140 -495 0 -495 0 0 -695z"/>
                    <path d="M8380 1810 l0 -140 210 0 210 0 0 -555 0 -555 155 0 155 0 0 555 0 555 213 2 212 3 3 138 3 137 -581 0 -580 0 0 -140z"/>
                    <path d="M9620 1255 l0 -695 500 0 501 0 -3 138 -3 137 -337 3 -338 2 0 135 0 135 315 0 315 0 0 135 0 135 -315 0 -315 0 0 145 0 145 340 0 340 0 0 140 0 140 -500 0 -500 0 0 -695z"/>
                    <path d="M10720 1255 l0 -695 155 0 155 0 0 265 0 265 58 0 57 0 164 -265 163 -265 308 0 307 0 23 62 c13 35 41 109 62 166 l40 102 236 -2 235 -3 61 -162 61 -163 168 0 c104 0 167 4 165 10 -1 5 -124 318 -273 695 l-270 685 -150 -2 -149 -3 -256 -652 c-140 -359 -258 -653 -261 -653 -6 0 -309 463 -309 473 0 4 7 7 16 7 31 0 134 63 175 107 167 182 132 504 -71 646 -104 72 -136 77 -526 77 l-344 0 0 -695z m685 373 c79 -86 49 -239 -53 -268 -21 -7 -97 -10 -177 -8 l-140 3 -3 158 -3 158 172 -3 171 -3 33 -37z m1119 -285 c36 -95 66 -175 66 -178 0 -3 -66 -5 -146 -5 -135 0 -146 1 -140 18 71 193 141 360 147 350 4 -7 37 -91 73 -185z"/>
                </g>
            </svg>
            </div>
        </div>
    )
}