// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCs5w8pIlB2u1tERQ8bc8AiS_JH4DnFSLE",
  authDomain: "lacasetera-91da3.firebaseapp.com",
  projectId: "lacasetera-91da3",
  storageBucket: "lacasetera-91da3.appspot.com",
  messagingSenderId: "639516020164",
  appId: "1:639516020164:web:74d64caa2811f1fa6fa891",
  measurementId: "G-4M7SJP7M0W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export default app;