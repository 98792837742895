import React from 'react';
import './styles.css';

export default function HomePage() {
    return(
        <div className='HomePage_Descripcion'>
            <p>La Casetera es un espectáculo retro integrado por artistas de gran trayectoria en la música popular. Neto Grazzia, Qurri Padilla, Yuli Flores, Fede Caballero, Omar Hech y Paco López hacen tributo a las canciones más clásicas del género grupero, cumbia, tejano, sonora y más.</p>
            <a href='/nosotros'> ¡Conoce a los integrantes de La Casetera! </a>
        </div>
    )
}