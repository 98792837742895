import React, { useEffect, useState } from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import TopMenu from './TopMenu.js'
import PersonalCard from './PersonalCard.js'

import "./styles.css"
import ImageGallery from './ImageGallery.js';
import BottomMenu from './BottomMenu.js';
import MainpageLogo from './MainpageLogo.js';
import Eventos from './Eventos.js'
import SideMenu from './SideMenu.js';
import { SideMenu_Button, SideMenu_TopSection} from './SideMenu.js';
import Lanzamientos from './Lanzamientos.js';
import HomePage from './HomePage.js';

function App() {
  return (

    <BrowserRouter>
        <Routes>
        <Route  
            path="/"
            element = {
              <div>
                <SideMenu/>
                <div className="workablePage">
                  <SideMenu_TopSection/>
                  
                  <img className='portadaGrupo' src={require('./media/grupales/lacasetera1.jpg')}></img>
                  <HomePage/>
                  <BottomMenu/>
                </div>
              </div>
            } 
        />

        <Route 
            path="/*"
            element = {
              <div>
                <SideMenu/>
                <div className="workablePage">
                  <SideMenu_TopSection/>
                  <BottomMenu/>
                </div>
              </div>
            } 
        />

          <Route 
            path="/nosotros"
            element = {
              <div>
                <SideMenu/>
                <div className="workablePage">
                <SideMenu_TopSection/>
                <h1 className='Sections_SectionTitle'>NOSOTROS</h1>
                <div className='personalCard_SidebarContainer'>
                <PersonalCard 
                  name='Yuli Flores'
                  profession='Voz'
                  facebookLink='https://www.facebook.com/YuliFloresa'
                  instagramLink='https://www.instagram.com/yulifloresa/'
                  twitterLink='https://twitter.com/yuliflores'
                  imageFile='./media/integrantes_Perfil/yuli2.jpg'
                  details= {['Desde los 10 años de edad comenzó su carrera cantando, actuando y conduciendo programas de las televisoras Televisa y Multimedios. En el 2007 ganó el concurso "Nuestras Voces VIP" de Televisa. En ese mismo año fue imagen de la campaña "Que Monterrey no tenga frío" al lado de otros conductores siendo la voz principal del tema', 'Por 4 años consecutivos fue la conductora de eventos masivos de Grupo Radio Alegría en la Arena Monterrey entrevistando a grandes artistas. Formó parte del elenco de programas como: Acábatelo, Mitad y Mitad, Destardes, adempas de ser la imagen de la campaña "El Corazón Más Grande".', 'En el 2018 inició una nueva faceta como locutora de radio con un programa de música Urbana de la estación 103.7. Es ex participante de "La Voz México 2020" en el equipo de Christian Nodal y Belinda, donde logró llegar a la etapa de los mejores 10 de cada equipo.', 'Actualmente trabaja para diferentes marcas con campañas publicitarias en sus redes sociales.']}
                  />
                <PersonalCard 
                  name='Gerardo "El Quirri" Padilla'
                  profession='Acordeon'
                  facebookLink='https://www.facebook.com/quirrimusic'
                  instagramLink='https://www.instagram.com/quirrimusic/'
                  twitterLink='N/A'
                  imageFile='./media/integrantes_Perfil/quirri2.jpg'
                  details= {['Creador del estilo musical del grupo LIMITE, considerado un fenomeno que logro dar una fuerte sacudida a los estandares establecido. Con solo su primer disco llegaron a vender mas de un millon de copias para despues llenar escenarios como El Auditorio Nacionall, Foro Sol y El Astrodome de Houston, entre otros.', ' "El Quirri" quien es ganador del Grammy, es considerado uno de los mejores acordeonistas que ha tenido el genero grupero por sus pegajosas introducciones musicales y un inigualable sonido.']}
                  />

                <PersonalCard 
                  name='Neto Grazzia'
                  profession='Bajo'
                  facebookLink='N/A'
                  instagramLink='N/A'
                  twitterLink='N/A'
                  imageFile='./media/integrantes_Perfil/neto2.jpg'
                  details= {['Por 23 años fue bajista de LOS GUARDIANES DEL AMOR, con quienes estuvo de gira continua por México, Estados Unidos, Centro, Sudamérica y España debido a que su música fue muy popular en todos los países de habla hispana.','Con mas de 4 millones de discos vendidos, Neto y los Guardianes fueron 5 veces nominados al Grammy y a los Premios OYE como mejor grupo.','Ganador del premio Furia Musical como Revelación del Año y Canción del Años en los TEXAS MUSIC AWARDS.',"Hoy en día es productor musical y ha realizado producciones para: Ana Barbara, Dana Paola, Ernesto D'Alessio, Nicho Hinojosa, Tatiana, Lolita Cortés, Gustavo Lara, Napoleón, entre otros"]}
                  />

                <PersonalCard 
                  name='Fede Caballero'
                  profession='Batería'
                  facebookLink='https://facebook.com/FEDECABALLEROFDFX'
                  instagramLink='https://www.instagram.com/fdfx/'
                  twitterLink='N/A'
                  imageFile='./media/integrantes_Perfil/fede2.jpg'
                  details= {['Mejor conocido como FDFX (Fedefex); baterista, percusionista y programador de géneros electrónicos reconocido por su trayectoria en la música, además de tener la herencia musical de su padre quien fue integrante y productor de LOS PLEBEYOS.','Fede incursiona en la escena musical con la agrupación LA VERBENA POPULAR, haciendo giras por México, USA y Europa.','Músico de sesión y ha estado en giras importantes con artistas como: ALICIA VILLARREAL, PANTEÓN ROCOCO, KUMBIA KINGS y PLASTILINA MOSH, entre otros.',"Recientemente fue nominado al Grammy con la agrupación A BAND OF BITCHES por la canción NORESTE CALIENTE, que se ha convertido en un himno de la música actual."]}
                  />

                <PersonalCard 
                  name='Omar Hech'
                  profession='Guitarra y Voz'
                  facebookLink='https://www.facebook.com/omhech'
                  instagramLink='https://www.instagram.com/omarhech/'
                  twitterLink='https://twitter.com/omarhech'
                  imageFile='./media/integrantes_Perfil/omar2.jpg'
                  details= {['Cantautor cubano graduado del conservatorio Guillermo Tomás de la Havana.','Por su sello único en la voz, aparte de un ritmo y sabor nato, los integrantes de LA CASETERA no dudaron en invitarlo a este proyecto después de escuchar algunas de sus canciones en las redes sociales.','Fue integrante de la banda SON DEL NENE con la cual llegó a México en el 2009.','En 2013 lanzó su primer sencillo a dueto con Tatiana Palacios con quien ha colaborado en varias ocasiones como compositor, guitarrista y co-autor del tema "No me Quiero Bañar" que tiene más de 500 millones de reproducciones en YouTube.']}
                  />

                  <PersonalCard 
                  name='Paquito López'
                  profession='Teclados y Voz'
                  facebookLink='N/A'
                  instagramLink='N/A'
                  twitterLink='N/A'
                  imageFile='./media/integrantes_Perfil/paco2.jpg'
                  details= {['Pianista, compositor, productor y arreglista. Comenzó a cantar y tocar piano de manera lírica a los 4 años de edad y posteriormente tomó clases de piano e ingresó en la Escuela Superior de Música y Danza. Inició su vida laboral a los 9 años',"Forma parte de uno de los mejores grupos versátiles del norte del país KUORUM, además de ser director musical de Ernesto D'Alessio y tecladista de LOS CLAXONS",'Músico de sesión en producciones importantes. Próximamente lanzará su disco de Jazz como pianista y compositor']}
                  />
                </div>
                <BottomMenu/>
                </div>
              </div>
                
            } 
        />

        <Route 
            path="/lanzamientos"
            element = {
              <div className='workablePage'>
                <SideMenu_Button/>
                <SideMenu/>
                <Lanzamientos/>
                <BottomMenu/>
              </div>
            } 
        />
    
        </Routes>
        </BrowserRouter>


    
  );
}

export default App;

/* <Route 
            path="/nosotros"
            element = {
              <div>
                <TopMenu/>
                <div className="workablePage">
                <MainpageLogo/>
                <PersonalCard 
                  name='Gerardo "El Quirri" Padilla'
                  profession='Acordeon'
                  facebookLink='https://www.facebook.com/quirrimusic'
                  instagramLink='https://www.instagram.com/quirrimusic/'
                  twitterLink='N/A'
                  imageFile='./media/integrantes_Perfil/quirri2.jpg'
                  details= {['Creador del estilo musical del grupo LIMITE, considerado un fenomeno que logro dar una fuerte sacudida a los estandares establecido. Con solo su primer disco llegaron a vender mas de un millon de copias para despues llenar escenarios como El Auditorio Nacionall, Foro Sol y El Astrodome de Houston, entre otros.', ' "El Quirri" quien es ganador del Grammy, es considerado uno de los mejores acordeonistas que ha tenido el genero grupero por sus pegajosas introducciones musicales y un inigualable sonido.']}
                  />

                  <PersonalCard 
                  name='Yuli Flores'
                  profession='Voz'
                  facebookLink='https://www.facebook.com/YuliFloresa'
                  instagramLink='https://www.instagram.com/yulifloresa/'
                  twitterLink='https://twitter.com/yuliflores'
                  imageFile='./media/integrantes_Perfil/yuli2.jpg'
                  details= {['Desde los 10 años de edad comenzó su carrera cantando, actuando y conduciendo programas de las televisoras Televisa y Multimedios. En el 2007 ganó el concurso "Nuestras Voces VIP" de Televisa. En ese mismo año fue imagen de la campaña "Que Monterrey no tenga frío" al lado de otros conductores siendo la voz principal del tema', 'Por 4 años consecutivos fue la conductora de eventos masivos de Grupo Radio Alegría en la Arena Monterrey entrevistando a grandes artistas. Formó parte del elenco de programas como: Acábatelo, Mitad y Mitad, Destardes, adempas de ser la imagen de la campaña "El Corazón Más Grande".', 'En el 2018 inició una nueva faceta como locutora de radio con un programa de música Urbana de la estación 103.7. Es ex participante de "La Voz México 2020" en el equipo de Christian Nodal y Belinda, donde logró llegar a la etapa de los mejores 10 de cada equipo.', 'Actualmente trabaja para diferentes marcas con campañas publicitarias en sus redes sociales.']}
                  />
  
                <PersonalCard 
                  name='Neto Grazzia'
                  profession='Bajo'
                  facebookLink='N/A'
                  instagramLink='N/A'
                  twitterLink='N/A'
                  imageFile='./media/integrantes_Perfil/neto2.jpg'
                  details= {['Por 23 años fue bajista de LOS GUARDIANES DEL AMOR, con quienes estuvo de gira continua por México, Estados Unidos, Centro, Sudamérica y España debido a que su música fue muy popular en todos los países de habla hispana.','Con mas de 4 millones de discos vendidos, Neto y los Guardianes fueron 5 veces nominados al Grammy y a los Premios OYE como mejor grupo.','Ganador del premio Furia Musical como Revelación del Año y Canción del Años en los TEXAS MUSIC AWARDS.',"Hoy en día es productor musical y ha realizado producciones para: Ana Barbara, Dana Paola, Ernesto D'Alessio, Nicho Hinojosa, Tatiana, Lolita Cortés, Gustavo Lara, Napoleón, entre otros"]}
                  />

                <PersonalCard 
                  name='Fede Caballero'
                  profession='Batería'
                  facebookLink='https://facebook.com/FEDECABALLEROFDFX'
                  instagramLink='https://www.instagram.com/fdfx/'
                  twitterLink='N/A'
                  imageFile='./media/integrantes_Perfil/fede2.jpg'
                  details= {['Mejor conocido como FDFX (Fedefex); baterista, percusionista y programador de géneros electrónicos reconocido por su trayectoria en la música, además de tener la herencia musical de su padre quien fue integrante y productor de LOS PLEBEYOS.','Fede incursiona en la escena musical con la agrupación LA VERBENA POPULAR, haciendo giras por México, USA y Europa.','Músico de sesión y ha estado en giras importantes con artistas como: ALICIA VILLARREAL, PANTEÓN ROCOCO, KUMBIA KINGS y PLASTILINA MOSH, entre otros.',"Recientemente fue nominado al Grammy con la agrupación A BAND OF BITCHES por la canción NORESTE CALIENTE, que se ha convertido en un himno de la música actual."]}
                  />

                <PersonalCard 
                  name='Omar Hech'
                  profession='Guitarra y Voz'
                  facebookLink='https://www.facebook.com/omhech'
                  instagramLink='https://www.instagram.com/omarhech/'
                  twitterLink='https://twitter.com/omarhech'
                  imageFile='./media/integrantes_Perfil/omar2.jpg'
                  details= {['Cantautor cubano graduado del conservatorio Guillermo Tomás de la Havana.','Por su sello único en la voz, aparte de un ritmo y sabor nato, los integrantes de LA CASETERA no dudaron en invitarlo a este proyecto después de escuchar algunas de sus canciones en las redes sociales.','Fue integrante de la banda SON DEL NENE con la cual llegó a México en el 2009.','En 2013 lanzó su primer sencillo a dueto con Tatiana Palacios con quien ha colaborado en varias ocasiones como compositor, guitarrista y co-autor del tema "No me Quiero Bañar" que tiene más de 500 millones de reproducciones en YouTube.']}
                  />

                  <PersonalCard 
                  name='Paquito López'
                  profession='Teclados y Voz'
                  facebookLink='N/A'
                  instagramLink='N/A'
                  twitterLink='N/A'
                  imageFile='./media/integrantes_Perfil/paco2.jpg'
                  details= {['Pianista, compositor, productor y arreglista. Comenzó a cantar y tocar piano de manera lírica a los 4 años de edad y posteriormente tomó clases de piano e ingresó en la Escuela Superior de Música y Danza. Inició su vida laboral a los 9 años',"Forma parte de uno de los mejores grupos versátiles del norte del país KUORUM, además de ser director musical de Ernesto D'Alessio y tecladista de LOS CLAXONS",'Músico de sesión en producciones importantes. Próximamente lanzará su disco de Jazz como pianista y compositor']}
                  />

                <BottomMenu/>
                </div>
              </div>
                
            } 
        />
*/