import React from 'react';
import './Casetera.css';

export default function Casetera() {
    return(

        <div className='Casetera_Container'>
            <div className='Casetera_Layout'>
            <iframe src="https://open.spotify.com/embed/playlist/3FK6ehYluY0hQfpFu8FcNK?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>
        </div>

    )
}

/*

<div className='Casetera_CasetteSection'>
                    <div className='Casetera_Division3'> </div>
                    <div className='Casetera_Division1'> <img className='Casetera_CasetteRueda' src= {require('./media/LogoCaseteraChico.png')}></img> </div>
                    
                    <div className='Casetera_Division2'> <iframe src="https://open.spotify.com/embed/track/31DtW1JEHLlnjhYROwGvNu?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe> </div>
                    
                    <div className='Casetera_Division1'> <img className='Casetera_CasetteRueda2' src= {require('./media/LogoCaseteraChico.png')}></img> </div>
                    <div className='Casetera_Division3'> </div>
                </div>

                <div className='Casetera_ButtonSection'>
                    <div className='Casetera_Button'> INICIO </div>
                    <div className='Casetera_Button'> EVENTOS </div>
                    <div className='Casetera_Button'> NOSOTROS </div>
                    <div className='Casetera_Button'> BLOG </div>
                </div>

*/