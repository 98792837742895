function ImageGallery() {
  return(
    <div className="Fotos_Galeria">
      <div className="Fotos_Seccion1">
        <img className="Fotos_Imagen" src={require("./media/28ENE23_La Casetera_C1-5.jpg")}></img>
        <img className="Fotos_Imagen" src={require("./media/28ENE23_La Casetera_C1-7.jpg")}></img>
      </div>
      <div className="Fotos_Seccion2">
        <img className="Fotos_Imagen" src={require("./media/28ENE23_La Casetera_C1-72.jpg")}></img>
        <img className="Fotos_Imagen" src={require("./media/28ENE23_La Casetera_C1-74.jpg")}></img>
      </div>
      
    </div>
    

  )
}

export default ImageGallery