function WorkInProgress() {
    return(
            <div className="enConstruccion">
                    <img className="imagenConstruccion" src={require("./media/LogoCaseteraCompleto.png")}></img>   
                    <div className="textoConstruccion">
                        <h1>Pagina en construccion</h1>
                        <h1>Espéralo muy pronto</h1>
                    </div>
            </div>
    )
}

export default WorkInProgress;