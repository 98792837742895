import React from 'react';
import './styles.css';
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Literata"></link>
export default function BottomMenu(){
    
return (

<div className='bottomMenu_Relative'>
    <div className='Menu_FlexContainer'>
    </div>
    <div className='Menu_CopyrightInfo'>
       <p>© La Casetera, 2023. Todos los derechos reservados.</p>
    </div>
        
</div>
)
}